<template>
  
   
   <!-- ======= Header ======= -->
   <header id="header" class="header d-flex align-items-center">
    <div style=" min-width: 150px;  margin-left: 10px; display: block;">
 
        <i class="bi d-flex " style="color: #ffffff; font-size: 1.1em; font-weight: 600;"><span>+7 (965) 366-48-00</span></i>
        <!-- <div style="color: #fdd870;   font-size: 0.8em; font-style: italic;">звонок по России бесплатный</div> -->
    </div>  
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between"  >
       <a href="/" class="logo d-flex align-items-center">
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <!-- <img src="assets/img/logo.png" alt=""> -->
        <!-- <h1>UpConstruction<span>.</span></h1> -->

        <object style="pointer-events: none; height: 20px" type="image/svg+xml" data="assets/img/fatec-logo.svg"></object>
      </a>

      <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
      <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
      <nav id="navbar" class="navbar">
        <ul>
          <li><a href="#hero" class="scrollto">Главный</a></li>
          <li><a href="#about" class="scrollto">О нас</a></li>
          <li><a href="#services" class="scrollto">Услуги</a></li>
          <li><a href="#projects" class="scrollto">Референс</a></li>
          <!-- <li><a href="blog.html">Blog</a></li> -->
          <!-- <li class="dropdown"><a href="#"><span>Dropdown</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>
              <li><a href="#">Dropdown 1</a></li>
              <li class="dropdown"><a href="#"><span>Deep Dropdown</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
                <ul>
                  <li><a href="#">Deep Dropdown 1</a></li>
                  <li><a href="#">Deep Dropdown 2</a></li>
                  <li><a href="#">Deep Dropdown 3</a></li>
                  <li><a href="#">Deep Dropdown 4</a></li>
                  <li><a href="#">Deep Dropdown 5</a></li>
                </ul>
              </li>
              <li><a href="#">Dropdown 2</a></li>
              <li><a href="#">Dropdown 3</a></li>
              <li><a href="#">Dropdown 4</a></li>
            </ul>
          </li> -->
          <li><a href="/contact" class="scrollto">Контакты</a></li>
          <li><a  href="https://shop.fatec.ru" class="scrollto">асутп-магазин</a></li>
        </ul>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="hero scrollto"  >

    <div class="info d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <h2 data-aos="fade-down">Системный интегратор <span></span> АСУ ТП</h2>
            <p data-aos="fade-up" style=" font-size: 1.5em;">
              Разрабатываем и внедряем решения в области информационных технологий для АСУ ТП  
            </p>
            
            <a data-aos="fade-up" data-aos-delay="200" href="#about" class="btn-get-started">Подробнее</a>
          </div>
        </div>
      </div>
    </div>

    <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="20000">

      <div class="carousel-item active" style="background-image: url(/assets/img/hero-carousel/hero-carousel-1.jpg)"></div>
      <div class="carousel-item" style="background-image: url(/assets/img/hero-carousel/hero-carousel-2.jpg)"></div>
      <div class="carousel-item" style="background-image: url(/assets/img/hero-carousel/hero-carousel-3.jpg)"></div>
      <div class="carousel-item" style="background-image: url(/assets/img/hero-carousel/hero-carousel-4.jpg)"></div>
      <div class="carousel-item" style="background-image: url(/assets/img/hero-carousel/hero-carousel-5.jpg)"></div>

      <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a>

      <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a>

    </div>

  </section><!-- End Hero Section -->

  <main id="main">

     <!-- ======= About Section ======= -->
     <section id="about" class="about scrollto">
      <div class="container" data-aos="fade-up">

        <div class="row position-relative">

          <div class="col-lg-7 about-img" style="background-image: url(assets/img/about.jpg);"></div>

          <div class="col-lg-7">
            <h2 >О компании</h2>
            <div class="our-story">
              <!-- <h4>Успешно работаем с 2015 года</h4> -->
              <h5>Успешно работаем — на рынке с 2015 года</h5>
            
              <p>FATEC — системный интегратор в сфере АСУ ТП, поставщик современных эффективных систем управления для широкого спектра технологических задач, требующих внимания к точности, постоянству и надёжности результатов.</p>
              <p>Компанию образовала команда инженеров и программистов в 2015 году. </p>
              <p >Аккредитованы Минцифры России.</p>
                

              <!-- <ul>
                <li><i class="bi bi-check-circle"></i> <span>Ullamco laboris nisi ut aliquip ex ea commo</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Duis aute irure dolor in reprehenderit in</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Ullamco laboris nisi ut aliquip ex ea</span></li>
              </ul>
              <p>Vitae autem velit excepturi fugit. Animi ad non. Eligendi et non nesciunt suscipit repellendus porro in quo eveniet. Molestias in maxime doloremque.</p>

              <div class="watch-video d-flex align-items-center position-relative">
                <i class="bi bi-play-circle"></i>
                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" class="glightbox stretched-link">Watch Video</a>
              </div> -->
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- End About Section -->

    <!-- ======= Stats Counter Section ======= -->
    <section id="stats-counter" class="stats-counter section-bg">
      <div class="container">

        <div class="row gy-4">

          <div class="col-lg-3 col-md-6">
            <div class="stats-item d-flex align-items-center w-100 h-100">
              <i >
                <object style="pointer-events: none; height: 42px" type="image/svg+xml" data="/assets/img/clients.svg"></object>
              </i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="54" data-purecounter-duration="1" class="purecounter"></span>
                <p>Клиентов</p>
              </div>
            </div>
          </div><!-- End Stats Item -->

          <div class="col-lg-3 col-md-6">
            <div class="stats-item d-flex align-items-center w-100 h-100">
              <i >
                <object style="pointer-events: none; height: 42px" type="image/svg+xml" data="/assets/img/projects.svg"></object>
              </i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="217" data-purecounter-duration="1" class="purecounter"></span>
                <p>Проектов</p>
              </div>
            </div>
          </div><!-- End Stats Item -->

          <div class="col-lg-3 col-md-6">
            <div class="stats-item d-flex align-items-center w-100 h-100">
              <i>
                <object style="pointer-events: none; height: 42px" type="image/svg+xml" data="/assets/img/exp.svg"></object>
              </i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
                <p>Лет опыта</p>
              </div>
            </div>
          </div>  
          <!-- End Stats Item -->

          <div class="col-lg-3 col-md-6">
            <div class="stats-item d-flex align-items-center w-100 h-100">
              <i class="bi bi-people color-pink flex-shrink-0"></i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
                <p>Сотрудников</p>
              </div>
            </div>
          </div><!-- End Stats Item -->

        </div>

      </div>
    </section><!-- End Stats Counter Section -->

    <!-- ======= Constructions Section ======= -->
 
    <!-- End Constructions Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg scrollto">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Услуги</h2>
          <p>Предлагаем широкий спектр инжиниринговых услуг в сфере АСУ ТП</p>
        </div>

        <div class="row gy-4">

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="service-item  position-relative">
              <div class="icon">
                <i class="fa-solid">
                  <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="/assets/img/s1.svg"></object>

                </i>
              </div>
              <h3>#01. Проработка</h3>
              <p>Обследование объекта, формализация задачи, оценка трудозатрат и сроков</p>
              <a href="/svc/s1" class="readmore stretched-link">Подробнее <i class="bi bi-arrow-right"></i></a>
            </div>
          </div><!-- End Service Item -->

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="fa-solid">

                  <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="/assets/img/s2.svg"></object>

                </i>
              </div>
              <h3>#02. Проектирование</h3>
              <p>Проектирования систем автоматизации по типовым и индивидуальным проектам</p>
              <a href="/svc/s2" class="readmore stretched-link">Подробнее <i class="bi bi-arrow-right"></i></a>
            </div>
          </div><!-- End Service Item -->

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="fa-solid">
                  <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="/assets/img/s3.svg"></object>

                </i>
              </div>
              <h3>#03. Сборка ШУ</h3>
              <p>Производство и поставка шкафов и щитов управления любой сложности</p>
              <a href="/svc/s3" class="readmore stretched-link">Подробнее <i class="bi bi-arrow-right"></i></a>
            </div>
          </div><!-- End Service Item -->

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="fa-solid">
                  <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="/assets/img/s4.svg"></object>

                </i>
              </div>
              <h3>#04. Программирование</h3>
              <p>Cовременные решения в области промышленного программирования АСУ ТП, АСДУ, АСКУЭ, АСУПР</p>
              <a href="/svc/s4" class="readmore stretched-link">Подробнее <i class="bi bi-arrow-right"></i></a>
            </div>
          </div><!-- End Service Item -->

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="fa-solid">
                  <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="/assets/img/s5.svg"></object>

                </i>
              </div>
              <h3>#05. Пуско-наладка</h3>
              <p>Шеф-монтаж  и пуско-наладочные работы оборудования</p>
              <a href="/svc/s5" class="readmore stretched-link">Подробнее <i class="bi bi-arrow-right"></i></a>
            </div>
          </div><!-- End Service Item -->

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="fa-solid">
                  <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="/assets/img/s6.svg"></object>

                </i>
              </div>
              <h3>#06. Снабжение</h3>
              <p>Поставка электрокомпонентов для вашего производства</p>
              <a href="/svc/s6" class="readmore stretched-link">Подробнее <i class="bi bi-arrow-right"></i></a>
            </div>
          </div><!-- End Service Item -->

        </div>

      </div>
    </section><!-- End Services Section -->

    <!-- ======= Alt Services Section ======= -->
   <!-- End Alt Services Section -->
 
    <!-- ======= Our Projects Section ======= -->
    <section id="projects" class="projects">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Референс</h2>
          <p>Компанией FATEC реализовано более 200 проектов автоматизации, география которых охватывает все регионы страны</p>
        </div>

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">
          <ul class="portfolio-flters" data-aos="fade-up" data-aos-delay="100">
            <li data-filter="*" class="filter-active">Все</li>
            <li data-filter=".filter-cooling">Холод</li>
            <li data-filter=".filter-ahu">Вентиляция</li>
            <li data-filter=".filter-water">Водоподготовка</li>
            <li data-filter=".filter-trans">Транспортировка</li>
            <li data-filter=".filter-prom">Общепром</li>
          </ul>
          <!-- End Projects Filters -->

          <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">

            <div class="col-lg-4 col-md-6 portfolio-item filter-cooling">
              <div class="portfolio-content h-100">
                <img src="/assets/img/projects/p1/p1-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Холод</h4>
                  <p>Промышленый холод</p>
                  <a href="/assets/img/projects/p1/p1-1.jpg" title="Промышленый холод" data-gallery="portfolio-gallery-remodeling" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p1" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div><!-- End Projects Item -->

            <div class="col-lg-4 col-md-6 portfolio-item filter-ahu">
              <div class="portfolio-content h-100">
                <img src="/assets/img/projects/p2/p2-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Вентиляция</h4>
                  <p>Вентиляция БЦ</p>
                  <a href="/assets/img/projects/p2/p2-1.jpg" title="Вентиляция БЦ" data-gallery="portfolio-gallery-construction" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p2" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div>
            
             <div class="col-lg-4 col-md-6 portfolio-item filter-water">
              <div class="portfolio-content h-100">
                <img  src="assets/img/projects/p3/p3-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Водоподготовка</h4>
                  <p>Участок водоподготовки АЭС</p>
                  <a href="assets/img/projects/p3/p3-1.jpg" title="Участок водоподготовки АЭС" data-gallery="portfolio-gallery-repairs" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p3" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div>  
            
            <!-- End Projects Item -->

            <div class="col-lg-4 col-md-6 portfolio-item filter-water">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p4/p4-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Водоподготовка</h4>
                  <p>Промышленный фильтр обратного осмоса</p>
                  <a href="/assets/img/projects/p4/p4-1.jpg" title="Промышленный фильтр обратного осмоса" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p4" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div>  
            
            <!-- End Projects Item -->

            <div class="col-lg-4 col-md-6 portfolio-item filter-trans">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p5/p5-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Транспортировка</h4>
                  <p>АСУ транспортировки сыпучих материалов</p>
                  <a href="/assets/img/projects/p5/p5-1.jpg" title="АСУ транспортировки сыпучих материалов" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p5" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div>  

            <div class="col-lg-4 col-md-6 portfolio-item filter-trans">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p6/p6-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Транспортировка</h4>
                  <p>АСУ линии фасовки в упаковку БИГ-БЭГ</p>
                  <a href="/assets/img/projects/p6/p6-1.jpg" title="АСУ линии фасовки в упаковку БИГ-БЭГ" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p6" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div> 

            <div class="col-lg-4 col-md-6 portfolio-item filter-ahu">
              <div class="portfolio-content h-100">
                <img src="/assets/img/projects/p7/p7-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Вентиляция</h4>
                  <p>Автоматика для вентиляции БЦ</p>
                  <a href="/assets/img/projects/p7/p7-1.jpg" title="Автоматика для вентиляции БЦ" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p7" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div> 

            <div class="col-lg-4 col-md-6 portfolio-item filter-ahu">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p8/p8-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Вентиляция</h4>
                  <p>Щит приточного вентилятора</p>
                  <a href="/assets/img/projects/p8/p8-1.jpg" title="Щит приточного вентилятора" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p8" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div> 

              <div class="col-lg-4 col-md-6 portfolio-item filter-prom">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p9/p9-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Общепром</h4>
                  <p>ОВиК энергоблока 18 МВт</p>
                  <a href="/assets/img/projects/p9/p9-1.jpg" title="ОВиК энергоблока 18 МВт" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p9" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
            </div>   

 

          <div class="col-lg-4 col-md-6 portfolio-item filter-prom">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p10/p10-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Общепром</h4>
                  <p>Модернизация АСУ ТП молочного завода</p>
                  <a href="/assets/img/projects/p10/p10-1.jpg" title="Увеличить" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p10" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
          </div> 

          <div class="col-lg-4 col-md-6 portfolio-item filter-prom">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p11/p11-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Общепром</h4>
                  <p>АСУ ТП горно-обогатительного комбината</p>
                  <a href="/assets/img/projects/p11/p11-1.jpg" title="Увеличить" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p11" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
          </div> 

          <div class="col-lg-4 col-md-6 portfolio-item filter-cooling">
              <div class="portfolio-content h-100">
                <img  src="/assets/img/projects/p12/p12-0.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Холод</h4>
                  <p>АСУ ТП холодильной установки</p>
                  <a href="/assets/img/projects/p12/p12-1.jpg" title="Увеличить" data-gallery="portfolio-gallery-book" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                  <a href="/proj/p12" title="Подробнее" class="details-link"><i class="bi bi-link-45deg"></i></a>
                </div>
              </div>
          </div> 

        </div><!-- End Projects Container -->

      </div><!-- End Projects Container -->

    
    </div> 
    </section>
    <!-- End Our Projects Section -->

        <!-- ======= Features Section ======= -->
        <section id="features" class="features section-bg">
      <div class="container" data-aos="fade-up">

        <ul class="nav nav-tabs row  g-2 d-flex">

          <li class="nav-item col-3">
            <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
              <h4>Опыт</h4>
            </a>
          </li><!-- End tab nav item -->

          <li class="nav-item col-3">
            <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
              <h4>4.0</h4>
            </a><!-- End tab nav item -->
          </li><!-- End tab nav item -->
          <li class="nav-item col-3">
            <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
              <h4>Сборка</h4>
            </a>
          </li><!-- End tab nav item -->

          <li class="nav-item col-3">
            <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
              <h4>Поставка</h4>
            </a>
          </li><!-- End tab nav item -->

        </ul>

        <div class="tab-content">

          <div class="tab-pane active show" id="tab-1">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <h3>Огромный опыт в сфере</h3>
                <p class="fst-italic">
                  Компанией FATEC реализовано более 200 проектов. Наши сотрдуники имеют многолетний реальный опыт во многих предметных областях
                </p>
                <ul>
                  <li><i class="bi bi-check2-all"></i> Промвентиляция</li>
                  <li><i class="bi bi-check2-all"></i> Промхолод</li>
                  <li><i class="bi bi-check2-all"></i> Водоподготовка</li>
                  <li><i class="bi bi-check2-all"></i> Транспортировка</li>
                  <li><i class="bi bi-check2-all"></i> Нефтехимия</li>

                </ul>
              </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                <img src="/assets/img/features-1.jpg" alt="" class="img-fluid">
              </div>
            </div>
          </div><!-- End tab content item -->

          <div class="tab-pane" id="tab-2">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                <h3>Индустрия 4.0</h3>
                <p class="fst-italic">
                  FATEC – использует современные методологии проектирования и разработки АСУ ТП, такие как цифровизация технологического процесса и применение IoT оборудования.
                </p>
                <ul>
                  
                  <li><i class="bi bi-check2-all"></i> Цифровизация технологического процесса</li>
                  <li><i class="bi bi-check2-all"></i> Разработка и внедрение уникальных IIoT решений</li>
                  <li><i class="bi bi-check2-all"></i> Производство электрооборудования</li>
                </ul>
              </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/features-2.jpg" alt="" class="img-fluid">
              </div>
            </div>
          </div><!-- End tab content item -->

          <div class="tab-pane" id="tab-3">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                <h3>Производство</h3>
                <p class="fst-italic">
                  Производство оборудования из качественных импортных материалов и лучших на рынке комплектующих.
                  Современное сборочное производство FATEC оснащённо станками и маркировочным оборудованием. Располагаем двумя площадками:
                </p>
                <ul>
                  <li><i class="bi bi-check2-all"></i> Москва — участок для небольших, эксклюзивных проектов (80 м2)</li>
                  <li><i class="bi bi-check2-all"></i> Королев — большой участок для реализации крупных проектов (300 м2)</li>
               </ul>

              </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/features-3.jpg" alt="" class="img-fluid">
              </div>
            </div>
          </div><!-- End tab content item -->

          <div class="tab-pane" id="tab-4">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                <h3>АСУТП-магазин</h3>
                <p class="fst-italic">
                  Мы разработали интернет-магазин АСУТП. Уникальность нашего магазина в том, что мы собрали для вас в нем самое лучшее и ходовое промышленное оборудование. 
                </p>
                <ul>
                  <li><i class="bi bi-check2-all"></i> Контроллеры (PLC)</li>
                  <li><i class="bi bi-check2-all"></i> Сенсорные панели управления (HMI)</li>
                  <li><i class="bi bi-check2-all"></i> Частотные преобразователи</li>
                  <li><i class="bi bi-check2-all"></i> Сетевое оборудование</li>
                  <li><i class="bi bi-check2-all"></i> КИП</li>
                </ul>
              </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/services.jpg" alt="" class="img-fluid">
              </div>
            </div>
          </div><!-- End tab content item -->

        </div>

      </div>
    </section><!-- End Features Section -->

    <!-- ======= Testimonials Section ======= -->

<!-- ======= Projet Details Section ======= -->
 

    <!-- ======= Recent Blog Posts Section ======= -->
 
    <!-- End Recent Blog Posts Section -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">

    <div class="footer-content position-relative">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
               
              <!-- <h3>UpConstruction</h3> -->
              <a href="/" class="logo d-flex align-items-center">
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <!-- <img src="assets/img/logo.png" alt=""> -->
        <!-- <h1>UpConstruction<span>.</span></h1> -->

            <object style="pointer-events: none; height: 24px" type="image/svg+xml" data="assets/img/fatec-logo.svg"></object>
          </a> 
              <p><br>
               
                <strong>Адрес:</strong> Москва, Новгородская, 1<br>
                <strong>Телефон:</strong> +7 (965) 366-48-00<br>
                <strong>Email:</strong> contact@fatec.ru<br>
              </p>
              <div class="social-links d-flex mt-3">
                <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-twitter"></i></a>
                <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-facebook"></i></a>
                <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-instagram"></i></a>
                <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div><!-- End footer info column-->

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Навигация</h4>
            <ul>
              <li><a href="#">Главный</a></li>
              <li><a href="#">О нас</a></li>
              <li><a href="#">Услуги</a></li>
              <li><a href="#">Референс</a></li>
              <li><a href="#">АСУТП-магазин</a></li>
            </ul>
          </div><!-- End footer links column-->

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Наши услуги</h4>
            <ul>
              <li><a href="/svc/s1">Проработка</a></li>
              <li><a href="/svc/s2">Проектирование</a></li>
              <li><a href="/svc/s3">Дистрибьюция</a></li>
              <li><a href="/svc/s4">Программирование</a></li>
              <li><a href="/svc/s5">Пуско-наладка</a></li>
              <li><a href="/svc/s6">Сервис</a></li>
            </ul>
          </div>
          
          <!-- End footer links column-->

           <div class="col-lg-2 col-md-3 footer-links">
            <h4>Наши товары</h4>
            <ul>
              <li><a href="http://shop.fatec.ru">Контроллеры (PLC)</a></li>
              <li><a href="http://shop.fatec.ru">Панели оператора (HMI)</a></li>
              <li><a href="http://shop.fatec.ru">Частотные преобразователи</a></li>
              <li><a href="http://shop.fatec.ru">Сетевое оборудование</a></li>
              <li><a href="http://shop.fatec.ru">КИП</a></li>
            </ul>
          </div>  
          <!-- End footer links column-->

          <!-- <div class="col-lg-2 col-md-3 footer-links">
            <h4>Nobis illum</h4>
            <ul>
              <li><a href="#">Ipsam</a></li>
              <li><a href="#">Laudantium dolorum</a></li>
              <li><a href="#">Dinera</a></li>
              <li><a href="#">Trodelas</a></li>
              <li><a href="#">Flexo</a></li>
            </ul>
          </div> -->
          
          <!-- End footer links column-->

        </div>
      </div>
    </div>

    <div class="footer-legal text-center position-relative">
      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>FATEC</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
          <a href="https://www1.fips.ru/fips_servl/fips_servlet?DB=RUTM&DocNumber=962645">Государственная регистрация №962645 </a>
        </div>
      </div>
    </div>

  </footer>
  <!-- End Footer -->

 

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

// export default {
//   name: 'HomeView',
//   components: {
//     HelloWorld
//   }
// }
</script>

